import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "selectTerritory" ]
  static values  = { url: String }

  connect() {
    $(this.selectTerritoryTarget).on("select2:select", (evt) => this.changeTerritory(evt))
  }

  disconnect() {
    $(this.selectTerritoryTarget).off("select2:select")
  }

  changeTerritory(evt) {
    const select = $(evt.currentTarget)

    $.ajax({
      type: "PUT",
      url: this.urlValue,
      data: {
        user: {
          current_territory_id: select.val()
        }
      },
      dataType: "json"
    }).done(() => {
      //console.log("done")
    })
  }
}
