// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import { Turbo, cable } from "@hotwired/turbo-rails"
import { activateSelect } from "../shared"

Rails.start()
ActiveStorage.start()

$(document).on("turbo:before-fetch-request", (event) => {
  $(".loader-wrapper").removeClass("hidden")
  window.showSearch = false
})

$(document).on("turbo:load", () => {
  activateSelect()
})

import "controllers"
