export default class ToolBox
  ###
    button: button clicked to open/close the window
    box: the box in which data will appear
  ###
  constructor: (button) ->
    @button = button
    @box    = $(button.data('target'))
    @dataUrl = button.data('url')

    @button.click (evt) => @toggleBox(evt)

    @box.on 'refresh', (evt) => @loadData()

    @box.on 'click', '.close-window', (evt) => @toggleBox(evt)

    $("body").on "keyup", (evt) =>
      if evt.keyCode is 27 and @box.is(':visible')
        @toggleBox(evt)


  toggleBox: (evt) ->
    evt.preventDefault()

    @box.toggle()

    if @box.is(':visible')
      @loadData()

  loadData: () ->
    if @dataUrl
      $.get(@dataUrl, @box.data("params"), (data) =>
        @box.html(data)
        @box.trigger('loaded')
      )
