// app/javascript/controllers/tabs_controller.js
import { Controller } from "stimulus"

// Connects to data-controller="tabs"
//
export default class extends Controller {
  static classes = ['active']
  static targets = ["btn", "tab", "quickAccessDropdown", "quickAccessSection", "quickAccessToggle"]
  static values = {defaultTab: String}

  connect() {
    // first, hide all tabs
    this.tabTargets.map(x => x.hidden = true)

    // then, show the default tab
    let selectedTab = this.tabTargets.find(element => element.id === `tab-${this.defaultTabValue}`)
    selectedTab.hidden = false

    // and activate the selected button
    let selectedBtn = this.btnTargets.find(element => element.id === `btn-${this.defaultTabValue}`)
    selectedBtn.classList.add(...this.activeClasses)
  }

  // switch between tabs
  // add to your buttons: data-action="click->tabs#select"
  select(event) {
    // find tab matching (with same id as) the clicked btn
    event.preventDefault()
    let selectedTab = this.tabTargets.find(element => element.id === event.params.target)
    let selectedSection

    if (selectedTab.hidden) {
      // hide everything
      this.tabTargets.map(x => x.hidden = true) // hide all tabs
      this.btnTargets.map(x => x.classList.remove(...this.activeClasses)) // deactive all btns


      // then show selected

      selectedTab.hidden = false // show current tab
      event.currentTarget.classList.add(...this.activeClasses) // activate current button

      if (this.hasQuickAccessToggleTarget) {
        selectedSection = this.quickAccessSectionTargets.find(element => element.id === event.params.target.replace("tab", "quick"))

        this.quickAccessSectionTargets.map(x => x.hidden = true)

        if (selectedSection) {
          selectedSection.hidden = false
        }

        window.scrollTo({
          top: 0,
          behavior: "smooth"
        })
      }
    }

    // // the code below enables showing no tabs
    // // clicking on an active tab will close it, hiding everything
    // else {
    //   // Hide all tabs, deactivate all buttons
    //   this.tabTargets.map(x => x.hidden = true)
    //   this.btnTargets.map(x => x.classList.remove(...this.activeClasses)) // deactive all btns
    // }
  }

  toggleQuickAccess(event = undefined) {
    this.quickAccessDropdownTarget.hidden = !this.quickAccessDropdownTarget.hidden
    if (event) {
      event.preventDefault()
    }

    if (this.quickAccessDropdownTarget.hidden) {
      this.quickAccessToggleTarget.classList.remove(...this.activeClasses)
    } else {
      this.quickAccessToggleTarget.classList.add(...this.activeClasses)
    }
  }

  scroll(event) {
    event.preventDefault()
    this.toggleQuickAccess()
    const elt = document.getElementById(event.currentTarget.getAttribute("href").replace("#", ""))
    const topPadding = document.querySelector(".data-index-wrapper").offsetTop

    window.scrollTo({
      top: elt.offsetTop - topPadding,
      behavior: "smooth"
    })
  }
}
