import { Controller } from "stimulus"
import { createCalendar } from "../shared/add_to_calendar"

export default class extends Controller {
  static targets = [ "calendarButtons" ]

  connect() {
    const info = JSON.parse(this.element.dataset.info)
    info.start = new Date(info.start)

    const addEvent = createCalendar({
      options: {
        class: 'add-event-button'
      },
      data: info
    })

    this.calendarButtonsTarget.appendChild(addEvent)
  }
}
