import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["searchInput", "filterable", "searchWrapper", "dateSelectDropdown", "filters", "filterInputs", "group", "noResults", "topTabs", "loader", "contentWrapper", "scrollRight"]



  /* Lifecycle methods */
  connect() {
    this.filters = {}
    this.filteredEltIds = []
    this.filteredElts = []
    this.searchTimeout = undefined

    if (window.showSearch) {
      this.openSearch()
    }

    if (!document.documentElement.hasAttribute("data-turbo-preview")) {

      if (this.hasTopTabsTarget) {
        const offset = this.topTabsTarget.querySelector(".active").offsetLeft

        if (offset > 300) {
          this.topTabsTarget.scrollBy({
            left: (offset - 15),
            behavior: 'smooth'
          })
        }
      }
    }
  }


  /* App methods */

  /* Custom page filters */
  applyFilter(filter, values) {
    this.filterableTargets.forEach((el) => {
      const filterableValue = el.getAttribute(`data-${filter}`)
      const eltId = el.getAttribute("id")

      if (!values.includes(filterableValue)) {
        el.classList.add("data-index__item-filterNotFound")

        if (this.filteredEltIds.indexOf(eltId) === -1) {
          this.filteredEltIds.push(eltId)
          this.filteredElts.push(el)
        }

      } else if (!this.filteredEltIds.includes(eltId)) {
        /* Remove the css not found class if the element is not already filtered by another filter */
        el.classList.remove("data-index__item-filterNotFound")
      }
    })
  }

  applyFilters() {
    if (Object.keys(this.filters).length === 0) {
      this.resetFilters()
    } else {
      /* initialize an array of filteredEltIds to apply a AND condition between multiple filters */
      this.filteredEltIds = []
      this.filteredElts = []

      for (const filter in this.filters) {
        this.applyFilter(filter, this.filters[filter])
      }
    }
    this.hideGroups()
    this.toggleFiltersWindow()
  }

  getInputsForFilter(filter) {
    return this.filterInputsTargets.filter((input) => input.dataset.mainFilterParam === filter && input.value !== "all")
  }

  resetFilters(evt = undefined) {
    this.filteredElts.forEach((el) => {
      el.classList.remove("data-index__item-filterNotFound")
    })

    /* Click happened on the reset button */
    if (evt) {
      this.filters = {}

      this.filterInputsTargets.forEach((elt) => {
        elt.checked = false
        if (elt.value === "all") {
          elt.classList.remove("partially-checked")
        }
      })
    }
    // this.toggleFiltersWindow()
  }

  scrollTabs(evt) {
    if (this.hasScrollRightTarget) {
      let maxScrollLeft = this.topTabsTarget.scrollWidth - this.topTabsTarget.clientWidth

      if (this.topTabsTarget.scrollLeft > maxScrollLeft - 5) {
        this.scrollRightTarget.classList.add("hidden")
      } else if (this.scrollRightTarget.classList.contains("hidden")) {
        this.scrollRightTarget.classList.remove("hidden")
      }
    }
  }

  manuallyScrollTabs() {
    this.topTabsTarget.scrollTo({left: this.topTabsTarget.scrollLeft + 300, behavior: "smooth"})
  }

  toggleAllFilters({ params: { filter }, target }) {
    const inputs = this.getInputsForFilter(filter)

    inputs.forEach((input) => {
      if (target.checked) {
        if (!input.checked) {
          this.toggleFilter({ params: { value: input.value, filter: filter } })
          input.checked = true
        }
      } else {
        if (input.checked) {
          this.toggleFilter({ params: { value: input.value, filter: filter } })
          input.checked = false
        }
      }
    })
    target.classList.remove("partially-checked")
  }

  toggleFilter({ params: { value, filter }, target }) {
    let currentFilters = this.filters[filter]

    if (currentFilters === undefined) {
      currentFilters = this.filters[filter] = []
    }

    const index = currentFilters.indexOf(`${value}`)

    if (index === -1) {
      currentFilters.push(`${value}`)
    } else {
      currentFilters.splice(index, 1)
    }

    if (target) {
      const checkAllInput = $(target).closest("ul").find(".filters__list-item-all input")

      if (currentFilters.length === 0) {
        delete this.filters[filter]
        checkAllInput.removeClass("partially-checked")
      } else if (currentFilters.length === this.getInputsForFilter(filter).length) {
        checkAllInput.prop("checked", true)
        checkAllInput.removeClass("partially-checked")
      } else {
        checkAllInput.addClass("partially-checked")
        checkAllInput.prop("checked", false)
      }
    }
  }

  /* Toggle index groups: hide if empty, show if not */
  hideGroups() {
    if (this.groupTargets.length > 0) {
      this.groupTargets.forEach((elt) => {
        /* easier to do that in jQuery... */
        const childs = $(elt).next().children().filter(":visible")
        if (childs.length === 0) {
          elt.classList.add("hidden")
        } else {
          elt.classList.remove("hidden")
        }
      })
    }

    if (this.filteredEltIds.length === this.filterableTargets.length) {
      this.noResultsTarget.classList.remove("hidden")
    } else {
      this.noResultsTarget.classList.add("hidden")
    }
  }

  /* Hide/Show Filters window */
  toggleFiltersWindow() {
    if (this.filtersTarget.classList.contains("hidden")) {
      this.filtersTarget.classList.remove("hidden")
      this.filtersTarget.classList.remove("closing")
    } else {
      this.filtersTarget.classList.add("closing")
      setTimeout(() => {
        this.filtersTarget.classList.add("hidden")
      }, 450);
    }
  }

  /* Hide/Show options dropdown */
  toggleOptions(e) {
    $(e.target).closest("ul").find(".options").toggle()
  }

  /* Date filter */
  filterByDate({ params: { date } }) {
    this.dateSelectDropdownTarget.getElementsByTagName("span")[0].innerHTML = date

    this.filterableTargets.forEach((el, i) => {
      const filterableDate = el.getAttribute("data-filter-date")

      el.classList.toggle("data-index__item-dateNotFound", filterableDate !== date)
    })

    $(this.dateSelectDropdownTarget).find(".options").toggle()

  }

  /* Search feature */
  /* Hide/Show search field */
  openSearch() {
    const searchURL = this.searchWrapperTarget.dataset.url

    if (window.location.href !== searchURL) {
      window.showSearch = true

      Turbo.visit(searchURL)
    } else {
      this.searchWrapperTarget.classList.remove("hidden")
    }
  }

  closeSearch() {
    this.searchWrapperTarget.classList.add("hidden")
    window.showSearch = false
  }

  searchIndex() {
    clearTimeout(this.searchTimeout)

    this.loaderTarget.classList.remove("hidden")
    this.contentWrapperTarget.classList.add("hidden")

    this.searchTimeout = setTimeout(() => {

      let lowerCaseFilterTerm = this.searchInputTarget.value.toLowerCase()

      this.filterableTargets.forEach((el, i) => {
        const filterableKey = el.getAttribute("data-filter-key").toLowerCase()

        el.classList.toggle("data-index__item-notFound", !filterableKey.includes(lowerCaseFilterTerm))
      })
      this.hideGroups()

      this.loaderTarget.classList.add("hidden")
      this.contentWrapperTarget.classList.remove("hidden")
    }, 400);

  }

}
