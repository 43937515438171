MS_IN_MINUTES = 60 * 1000

formatTime = (date) ->
  date.toISOString().replace(/-|:|\.\d+/g, '')

replaceNewlines = (event) ->
  event.description.toString().replace(/\\n/g, '<br />')

calculateEndTime = (event) ->
  if event.end then formatTime(event.end) else formatTime(new Date(event.start.getTime() + event.duration * MS_IN_MINUTES))

toURL = (str) ->
  return encodeURI(str or '')

calendarGenerators =
  google: (event) ->
    startTime = formatTime(event.start)
    endTime = calculateEndTime(event)
    description = replaceNewlines(event)

    href = [
      'https://www.google.com/calendar/render'
      '?action=TEMPLATE'
      '&text=' + (event.title or '')
      '&dates=' + (startTime or '')
      '/' + (endTime or '')
      '&details=' + (description or '')
      '&location=' + (event.address or '')
      '&sprop=&sprop=name:'
    ].join('')
    "<a class='icon-google' title='Google agenda' target='_blank' href=\"#{ toURL(href.replace('"', '')) }\"></a>"

  yahoo: (event) ->
    eventDuration = if event.end then (event.end.getTime() - event.start.getTime()) / MS_IN_MINUTES else event.duration
    # Yahoo dates are crazy, we need to convert the duration from minutes to hh:mm
    yahooHourDuration = if eventDuration < 600 then '0' + Math.floor(eventDuration / 60) else Math.floor(eventDuration / 60) + ''
    yahooMinuteDuration = if eventDuration % 60 < 10 then '0' + eventDuration % 60 else eventDuration % 60 + ''
    yahooEventDuration = yahooHourDuration + yahooMinuteDuration
    # Remove timezone from event time
    st = formatTime(new Date(event.start - (event.start.getTimezoneOffset() * MS_IN_MINUTES))) or ''
    href = [
      'http://calendar.yahoo.com/?v=60&view=d&type=20'
      '&title=' + (event.title or '')
      '&st=' + st
      '&dur=' + (yahooEventDuration or '')
      '&desc=' + (event.description.replace(/\n/g, '<br />'))
      '&timezone=' + 'UTC'
      '&in_loc=' + (event.address or '')
    ].join('')
    "<a class='icon-yahoo' title='Yahoo' target='_blank' href=\"#{ toURL(href.replace('"', ''))  }\"></a>"

  ics: (event, eClass, calendarName) ->
    startTime = formatTime(event.start)
    endTime = calculateEndTime(event)
    # 'URL:' + document.URL,
    href = 'data:text/calendar;charset=utf8,' + [
      'BEGIN:VCALENDAR'
      'VERSION:2.0'
      'X-WR-TIMEZONE:' + 'UTC'
      'BEGIN:VEVENT'
      'DTSTART:' + (startTime or '')
      'DTEND:' + (endTime or '')
      'SUMMARY:' + (event.title or '')
      'DESCRIPTION:' + (event.description or '')
      'LOCATION:' + (event.address or '')
      'TIMEZONE:' + 'UTC'
      'END:VEVENT'
      'END:VCALENDAR'
    ].join('\n')

    "<a class='ics-download #{ eClass }' download=\"#{ event.filename }.ics\" title='#{ calendarName }' href=\"#{ toURL(href.replace('"', ''))  }\"></a>"

  ical: (event) ->
    @ics event, 'icon-ical', 'iCal'

  outlook: (event) ->
    @ics event, 'icon-outlook', 'Outlook'

generateCalendars = (event) ->
  {
    google: calendarGenerators.google(event)
    yahoo: calendarGenerators.yahoo(event)
    ical: calendarGenerators.ical(event)
    outlook: calendarGenerators.outlook(event)
  }

# Make sure we have the necessary event data, such as start time and event duration

validParams = (params) ->
  params.data != undefined and params.data.start != undefined and (params.data.end != undefined or params.data.duration != undefined)

generateMarkup = (calendars, clazz, calendarId) ->
  result = document.createElement('div')
  Object.keys(calendars).forEach (services) ->
    result.innerHTML += calendars[services]
    return
  result.className = 'add-to-calendar'
  if clazz != undefined
    result.className += ' ' + clazz
  result.id = calendarId
  result

getClass = (params) ->
  if params.options and params.options.class
    return params.options.class
  return

getOrGenerateCalendarId = (params) ->
  if params.options and params.options.id then params.options.id else Math.floor(Math.random() * 1000000)
  # Generate a 6-digit random ID

export createCalendar = (params) ->
  if !validParams(params)
    console.log 'Event details missing.'
    return
  generateMarkup generateCalendars(params.data), getClass(params), getOrGenerateCalendarId(params)

# $(".data-grid").on("click", "a.ics-download", (evt) ->
#   console.log(evt)
# )
