import { Controller } from 'stimulus'
import { activateSelect, deactiveSelect } from "../shared"

export default class extends Controller {

  static targets = ["section"]

  connect() {
    //this.element.addEventListener('turbo:submit-end', this.handleSubmit)
    activateSelect()

    /* Hide empty sections */
    // this.sectionTargets.forEach(section => {
    //   if (section.getElementsByClassName("info-item").length === 0) {
    //     section.classList.add("hidden")
    //   }
    // })

    /* Get z-index of last opened modal, change new modal z-index to be on top */
    let lastZIndex = 0
    document.querySelectorAll(".modal").forEach((modal) => {
      const modalIndex = parseInt($(modal).css("z-index"))
      if (modalIndex > lastZIndex) {
        lastZIndex = modalIndex
      }
    })

    $(this.element).css("z-index", lastZIndex + 10)
    $(".loader-wrapper").addClass("hidden")
  }

  disconnect() {
    //this.element.removeEventListener('turbo:submit-end', this.handleSubmit)
    deactiveSelect($(this.element))
  }

  close() {
    // Add class to start close animation
    this.element.classList.add("modal-closed")
    $(".loader-wrapper").addClass("hidden")

    setTimeout(() => {
      // Remove src reference from parent frame element
      // Without this, turbo won't re-open the modal on subsequent clicks
      this.element.closest(".modal-frame").src = undefined

      // Remove the modal element after the fade out so it doesn't blanket the screen
      this.element.remove()
    }, 400);
  }

  updateData({ params: { url, attribute, value, id } }) {
    $.ajax({
      type: "PUT",
      url: url,
      data: {
        films_territory: {
          [attribute]: value
        }
      },
      format: "html"
    }).done((data) => {
      const elementName = `.${attribute}-dropdown`
      const newDropdown = $(data).find(elementName)
      const filmUpdatedElt = $(`#item-film_${id}`)

      $(this.element).find(elementName).html(newDropdown.html())
      filmUpdatedElt.replaceWith($(data).find('.index-info').html())
    })
  }

  handleKeyup(e) {
    if (e.code == 'Escape') {
      this.close()
    }
  }

  handleSubmit = (e) => {
    if (e.detail.success) {
      this.close()
    }
  }
}
