import "select2"

export activateSelect = () ->
  $("select.select:not([multiple])").select2({
    theme: "classic"
  })

  $("select.select[multiple]").select2({
    theme: "classic",
    multiple: true,
    closeOnSelect: false
  })

export deactiveSelect = (element) ->
  $(element).find("select:not([multiple])").select2('destroy')

  $(element).find("select[multiple]").select2('destroy')
