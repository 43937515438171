export activateToggles = () ->
  # Update boolean when clicking on checkbox
  $(".toggle-checkbox").on("click", () ->
    $.post({
      url: $(this).data("toggle-link"),
      data: {
        redirect_url: window.location.href
      },
      success: (data) ->
        #console.log "Updated with success"
    })
  )

  $(".toggle-menu").on("click", (evt) ->
    evt.preventDefault()
    evt.stopPropagation()
    $(".toolbar").toggle("slow")
  )
